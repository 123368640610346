import React from "react"
import styled from "@emotion/styled"

import mediaqueries from "@styles/media"

import Image from "@components/Image"
import { Icon } from "@types"
import { graphql, useStaticQuery } from "gatsby"

const iconQuery = graphql`
  {
    logoIcon: file(relativePath: { eq: "avatars/icon.png" }) {
      small: childImageSharp {
        fluid(maxWidth: 24, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
`

const Logo: Icon = ({ fill = "white" }) => {
  const results = useStaticQuery(iconQuery)
  return (
    <>
      <IconContainer>
        <RoundedImage src={results.logoIcon.small.fluid} />
      </IconContainer>
      <LogoTextContainer>
        <span className="LogoText__Desktop">Passiveincome.io</span>
      </LogoTextContainer>
    </>
  )
}

export default Logo

const LogoTextContainer = styled.div`
  margin-left: 0.5em;
  opacity: 0.5;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 1.15;
  color: ${(p) => p.theme.colors.primary};
  font-family: ${(p) => p.theme.fonts.sansSerif};
  a {
    color: ${(p) => p.theme.colors.accent};
  }

  .LogoText__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .LogoText__Desktop {
      display: none;
    }
    
    .LogoText__Mobile{
      display: block;
    }
  `}
`

const IconContainer = styled.div`
  width: 24px;
`

const RoundedImage = styled(Image)`
  border-radius: 50%;
`
